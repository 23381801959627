import React, { useContext } from "react";
import { NavigationItem } from "~/components/containers/app-layout";
export interface ICommandPaletteContext {
  toggle: () => void;
  registerNavigation: (navigation: NavigationItem[]) => any;
}
export const CommandPaletteContext =
  React.createContext<ICommandPaletteContext>({
    toggle: () => {},
    registerNavigation: () => {},
  });

export const useToggleCommandPalette = () =>
  useContext(CommandPaletteContext).toggle;

export const useRegisterNavigationCommandPalette = () =>
  useContext(CommandPaletteContext).registerNavigation;
